import http from '@/utils/request'
import Qs from 'qs'
export default {
	//上传匹配图片(添加病历)
	uploadImg: (data, config) => http.post('/wx/recruit/img/upload/uploadImg', data, config), 
	//上传删除图片(添加病历)
	delFile: (data) => http.post('/wx/recruit/img/upload/delFile', Qs.stringify(data)),
	
	//上传匹配图片(更新病历)
	uploadImg1: (data, config) => http.post('/wx/recruit/img/upload/uploadImg1', data, config),  
	//上传删除图片(更新病历)
	delFile1: (data) => http.post('/wx/recruit/img/upload/delFile1', Qs.stringify(data)),
	
	
	
	//更新获取所有图片
	getBl: (data) => http.post('/wx/recruit/img/upload/getBl', Qs.stringify(data)),
	//判断当前用户是否有绑定患者
	hasInquiry: (data) => http.get('/wx/recruit/img/upload/hasInquiry', { params: data }),
}