<template>
	<div id="pictureContainer">
		<input type="file" name="app_file" id="file" :class="chooseBtn"  @change="compPreChoose($event)" accept="image/*" ></input>
		<div class="img_box_container">
			{{title}}
		</div>
		<div class="pictureBox" v-for="(item,index) in images" :key="index">
			<div class="picture" @click="showPreview(item.path)">
				<img :src="baseUrl+item.path" alt="">
				
			</div>
			<span class="delIcon" @click="delImg(index,item.fileId)">
				<img :src="iconDel" alt="">
			</span>
		</div>
		
		<div class="chooseImage"  @click="chooseImg">
			<div class="chooseImage_icon">
				<img :src="camera" alt="">
			</div>
			<div class="chooseImage_tips">{{tips}}</div>
		</div>
	</div>
</template>

<script>
	import {
		Icon,
		Toast,
		ImagePreview,
		Dialog
	} from 'vant';

	import uploadPicture from '@/components/medicalRecord/uploadPicture';
	import medicalRecordApi from '@/api/medicalRecord/medicalRecordApi.js'
	import iconDel  from '@assets/icon/icon_del.png';
	import camera  from '@assets/icon/camera.png';
	import {
		readAsBase,
		judgeSizeAndResize
	} from '../../utils/blob.js'
	export default {
		components: {
			[Icon.name]: Icon,
			[Toast.name]:Toast,
			[ImagePreview.name]:ImagePreview,
			[Dialog.name]:Dialog,
		},
		props: {	
			//当前文件选择器的类名
			chooseBtn: {
				type: String,
				default () {
					return "file"
				}
			},
			//当前标题
			title:{
				type: String,
				default () {
					return ""
				}
			},
			//0添加  1更新
			type:{
				type: String,
				default () {
					return "0"
				}
			},
			imgType:{
				type: String,
				default () {
					return "1"
				}
			},
			recordId:{
				type: [String,Number],
				default () {
					return ""
				}
			},
			//上传图片的提示语
			tips:{
				type: String,
				default () {
					return "上传图片"
				}
			},
			//更新病历下的图片列表
			blList:{
				type:Array,
				default () {
					return []
				}
			}
		},
		data() {
			return {
				iconDel:iconDel,
				camera:camera,
				images:[],
				baseUrl: this.$store.state.baseUrl,
			}
		},
		methods: {
			//弹出文件选择器
			chooseImg() {
				document.getElementsByClassName(this.chooseBtn)[0].click();
			},
			/* 图片删除 */
			delImg(index,fileId) {
				Dialog.confirm({
				  title: '删除图片',
				  message: '您确认要删除图片吗？'
				}).then(() => {
					if(this.type==0){
						medicalRecordApi.delFile({fileId:fileId}).then(rs=>{
							if(rs.status==1){
								this.images.splice(index, 1);
							}else{
								Toast({
									message: '删除失败,请重试!',
									position: 'bottom'
								});
							}
						})			
					}else if(this.type==1){
						medicalRecordApi.delFile1({fileId:fileId}).then(rs=>{
							if(rs.status==1){
								this.images.splice(index, 1);
							}else{
								Toast({
									message: '删除失败,请重试!',
									position: 'bottom'
								});
							}
						})			
					}
				}).catch(() => {
					Toast({
						message: "已取消",
						position: 'bottom'
					});
				});	
			},
			//弹出图片预览
			showPreview(path){
				ImagePreview([this.baseUrl+path])
			},
			/* 图片选择完毕触发 测试压缩 */
			compPreChoose(e) {
				let file = e.target.files[0];
				console.log(file)
				judgeSizeAndResize(file).then((file1)=>{
					console.log(file1)
					let config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					};
					let param = new FormData(); //创建form对象						
					param.append('file', file1);
					param.append('recordId', this.recordId); 
					param.append('imgType', this.imgType);
					if(this.type==0){
						medicalRecordApi.uploadImg(param, config).then(rs=>{
							let info={
								path:rs.data.filePath,
								fileId:rs.data.fileId,
							}
							this.images.push(info)
							document.getElementsByClassName(this.chooseBtn)[0].value = null;
						})
						
					}else if(this.type==1){
						medicalRecordApi.uploadImg1(param, config).then(rs=>{
							let info={
								path:rs.data.filePath,
								fileId:rs.data.fileId,
							}
							this.images.push(info)
							document.getElementsByClassName(this.chooseBtn)[0].value = null;
						})
						
						
					}
					
				})

			},
			//更新初始化 
			updateInit(){
				for(let i=0;i<this.blList.length;i++){
					if(this.blList[i].imgType==this.imgType){
			
						let info={
							path:this.blList[i].imgUrl,
							fileId:this.blList[i].id,
						}
						this.images.push(info)
						
					}
				}
				// ImagePreview(box);
			},
			//判断组件内图片是否为空
			isNull(){
				if(this.images.length==0){
					return true;
				}else{
					return false;
				}
			},
		},
		mounted() {
			this.updateInit();
		}
		
	}
	
</script>

<style scoped="scoped" lang="less">
	#pictureContainer{
		width:100%;
		margin-top: 20px;
		.img_box_container{
			width: 100%;
			font-size:16px;
			font-weight:600;
			color:rgba(49,49,52,1);
			text-align: left;
			margin-bottom: 15px;
		}
		#file {
			display: none;
		}
		.chooseImage{
			width:90px;
			height:90px;
			background:rgba(247,247,247,1);
			border-radius:5px;
			display: inline-block;
			// background: red;
			div{
				display: inline-block;
				width: 100%;
				height: 49.5%;
				vertical-align: text-top;
				float: left;
				border-radius: 5px;
			}
			.chooseImage_icon{
				// background: #1989FA;
				text-align: center;
				// margin-top: 5px;
				img{
					vertical-align: text-bottom;
					width:27px;
					height:24px;
					// margin-top: 10px;
				}
			}
			.chooseImage_tips{
				// background: #7D7E80;
				font-size:14px;
				font-weight:400;
				color:rgba(90,90,93,1);
				line-height:20px;
				padding: 0px 10px;
				text-align: center;
			}
			
		}
		.pictureBox{
			width:90px;
			height:90px;
			border-radius:5px;
			display: inline-block;
			
			margin-right: 15px;
			position: relative;
			
			.picture{
				display: inline-block;
				width:90px;
				height:90px;
				float: left;
				img{
					width:90px;
					height:90px;
					border-radius:5px;
				}
			}
			.delIcon{
				display: inline-block;
				position: absolute;
				width: 25px;
				height: 25px;
				// background: #1989FA;
				right: -10px;
				top: -10px;
				img{
					width: 25px;
					height: 25px;
					display: inline-block;
					float: left;
				}
			}
		}
		
	}
</style>
