//压缩质量
const quality = 0.5;
export function judgeSizeAndResize(e, limitSize = 0.9) {
	let file = e;
	const size = file.size / 1024 / 1024
	console.log("压缩前的图片：")
	console.log(file)
	return new Promise((resolve, reject) => {
		if (size <= limitSize) {
			resolve(file)
		} else {
			const type = file.type
			const date = new Date()
			const time = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate();
			const filename = 'create-' + time + '-already-resize' + file.name;
			resizeImage(file, quality, filename, type, resolve)
		}
	}) 
}

export function resizeImage(file, quality, filename, type, resolve) {
	filetoDataURL(file, function(dataUrl) {
		dataURLtoImage(dataUrl, function(image) {
			const dataUrl = canvasResizeToDataURL(imagetoCanvas(image), quality, type)
			const file = dataURLtoFile(dataUrl, filename)
			console.log("压缩后的图片：")
			console.log(file)
			resolve(file)
		})
	})
}
function dataURLtoFile(dataUrl, filename) {
	const arr = dataUrl.split(',')
	const mime = arr[0].match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, {
		type: mime
	})
}


function imagetoCanvas(image) {
	const canvas = document.createElement("canvas")
	let ctx = canvas.getContext('2d')
	canvas.width = image.width
	canvas.height = image.height
	ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
	return canvas
}
function canvasResizeToDataURL(canvas, quality, type = 'image/jpeg') {
	return canvas.toDataURL('image/jpeg', quality)
}

function filetoDataURL(file, fn) {
	const reader = new FileReader()
	reader.onloadend = function(e) {
		fn(e.target.result);
	}
	reader.readAsDataURL(file)
}

function dataURLtoImage(dataurl, callback) {
	const img = new Image()
	img.src = dataurl
	img.onload = function() {
		callback(img)
	}
}